// I don't know how to type this, check https://image.nuxtjs.org/advanced/custom-provider if they updated docs
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getImage(src: string, { modifiers, baseURL } = {}, { options, nuxtContext, $img }) {
  const { width, height, format, ...providerModifiers } = modifiers;

  let url = src;
  const params = [];
  if (width) {
    params.push(`w=${width}`);
  }
  if (height) {
    params.push(`h=${height}`);
  }
  if (providerModifiers?.quality) {
    params.push(`q=${providerModifiers.quality}`);
  }
  if (providerModifiers?.format) {
    params.push(`f=${providerModifiers.format}`);
  } else if (format) {
    params.push(`f=${format}`);
  }
  if (params.length) {
    url += '?' + params.join('&');
  }

  return {
    url,
  };
}
