
import * as storyblokRuntime$f99HEA5dbo from '/opt/atlassian/pipelines/agent/build/node_modules/@nuxt/image/dist/runtime/providers/storyblok'
import * as customProviderRuntime$CZZTHPprBE from '/opt/atlassian/pipelines/agent/build/providers/norce.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 767,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536,
    "mob375": 375,
    "mob390": 390,
    "mob414": 414,
    "desk": 1024,
    "deskStd": 1372,
    "deskLarge": 1600,
    "deskLargest": 1920
  },
  "presets": {
    "standard": {
      "modifiers": {
        "format": "webp",
        "quality": 70,
        "densities": "1x 2x"
      }
    },
    "plpimage": {
      "modifiers": {
        "format": "webp",
        "quality": 70,
        "densities": "1x 2x"
      }
    },
    "pdpimage": {
      "modifiers": {
        "format": "webp",
        "quality": 65,
        "densities": "1x 2x"
      }
    },
    "full": {
      "modifiers": {
        "format": "webp",
        "quality": 100,
        "densities": "1x 2x"
      }
    }
  },
  "provider": "storyblok",
  "domains": [
    "a.storyblok.com",
    "az666937.vo.msecnd.net",
    "vardvaskan.cdn.storm.io"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['storyblok']: { provider: storyblokRuntime$f99HEA5dbo, defaults: {"baseURL":"https://a.storyblok.com"} },
  ['norce']: { provider: customProviderRuntime$CZZTHPprBE, defaults: {} }
}
        